/* eslint-disable max-len */

export const colors = {
  // primary
  orange: "#BA4637",
  white: "#fff",
  brownDark: "#332321",
  brownLite: "#564745",
  maroon: "#680F1C",
  lime: "#EAFD00",

  // secondary
  pink: "#C96D72",
  peach: "#F09F89",
  purple: "#9E4F6C",
  pinkLite: "#FED1C5",
  pastel: "#A35257",

  // neutral
  black: "#111111",
  smoke: "#30353F",
  silver: "#D4D4D4",
  cream: "#F1EDE5",
};

export const breakpoints = {
  mobileS: "320px",
  mobile: "767px",
  tablet: "1200px",
  laptop: "1440px",
  desktop: "2560px",
};

export const eases = {
  inOut: "cubic-bezier(0.75, 0, 0.25, 1)",
  out: "cubic-bezier(0.0, 0.75, 0.25, 1)",
  in: "cubic-bezier(0.75, 0.0, 1, 0.25)",
};

export const zIndices = {
  hideBehind: -1,
  lightningClip: 1,
  championImg: 2,
  videoOverlay: 3,
};
