import * as React from "react";
import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";

export const homeFragment = graphql`
  fragment HomeSeoFragment on Contentstack_jfg_home_page {
    homeSeo: seo_og_tags {
      pageTitle: page_title
      pageDescription: page_description
      siteImage: site_image {
        url
      }
      url
      siteName: site_name
    }
  }
`;

export const mediaFragment = graphql`
  fragment MediaSeoFragment on Contentstack_jfg_media_page {
    mediaSeo: seo_meta_data {
      pageTitle: page_title
      pageDescription: page_description
      siteImage: site_image {
        url
      }
      url
      siteName: site_name
    }
  }
`;

export const purchaseFragment = graphql`
  fragment PurchaseSeoFragment on Contentstack_jfg_purchase_page {
    purchaseSeo: seo_meta_data {
      pageTitle: page_title
      pageDescription: page_description
      siteImage: site_image {
        url
      }
      url
      siteName: site_name
    }
  }
`;

export interface SeoData {
  pageTitle: string;
  pageDescription: string;
  siteImage: {
    url: string;
  };
  url: string;
  siteName: string;
}

interface Props {
  data: SeoData;
  url: string;
  locale: string;
}

const Seo: React.FC<Props> = ({ data, url, locale }) => {
  const { pageTitle, pageDescription, siteImage, siteName } = data;

  const ogLocale = locale.replace(/(-[a-z]{2,})/, (v) => v.replace("-", "_").toUpperCase());

  return (
    <GatsbySeo
      title={pageTitle}
      description={pageDescription}
      canonical={url}
      openGraph={{
        url: url,
        title: pageTitle,
        description: pageDescription,
        images: [
          {
            url: siteImage.url,
          },
        ],
        site_name: siteName,
        locale: ogLocale,
      }}
    />
  );
};

export default Seo;